<template>
  <div class="login-register">
      <div class="login-register__form-holder">
        <!-- Login Form -->  
        <div class="row" v-if="currentRouteName === 'login'">
          <LoginForm />
          <RegisterLink/>
        </div>
        <!-- registration form -->
        <div class="row" v-if="currentRouteName === 'register'">  
          <RegistrationForm />
          <LoginLink />
        </div>  
      </div> 
  </div>  
</template>
<script>
  import LoginForm from "../components/LoginForm";
  import RegistrationForm from "../components/RegisterForm";
  import RegisterLink from "../components/RegisterLink";
  import LoginLink from "../components/LoginLink";
  export default {
    name: 'loginRegistration',
    components: {
      LoginForm,
      RegisterLink,
      RegistrationForm,
      LoginLink
    },
    computed: {
      currentRouteName() {
        return this.$route.name;
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import '../assets/scss/_variables.scss';

  .login-register {
    position: absolute;
    width: 100%;
    min-height: 100vh;
    height: auto;
    background-color: rgb(231, 235, 234);
    background-image: url('../assets/images/repeat-bg.png');
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    flex-direction: column;
  }

  .login-register__form-holder {
    max-width: 1024px;
    width: calc(100% - 20px);
    height: auto;
    min-height: 500px;
    margin: 10px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 1px 11px 10px 10px rgba(0,0,0,0.05);

    .row {
      height: 100%;
      min-height: 500px;
      align-items: 'stretch';

      .col {
        min-height: 500px;
      }
    }
  }

  @media (max-width: $breakpoint-small) {
    .login-register__form-holder {
      overflow: auto;
    }

    .login-register__form-holder__right {
      display: none;
    }
  }
</style>
<template>
  <div>
    <div class="temp-banner-holder">
      <img alt="kraite logo" src="../assets/images/temp-banner2.png" class="temp-banner-holder__img">
      <div class="temp-banner-holder__content">
        <img alt="kraite logo" src="../assets/images/kraite-logo3.png" class="logo">
      </div>  
    </div>
    <div class="container">
      <div class="row">
        <div class="col col--1">
          <span>Heading 1</span>
        </div>  
        <div class="col">
          <h1 class="no-margins">Heading One Valera Round</h1>
        </div>  
      </div>  
      <div class="row">
        <div class="col col--1">
          <span>Heading 2</span>
        </div>  
        <div class="col">
          <h2 class="no-margins">Heading Two Valera Round</h2>
        </div>
      </div>    
      <div class="row">
        <div class="col col--1">
          <span>Heading 3</span>
        </div>  
        <div class="col">
          <h3 class="no-margins">Heading Three Valera Round</h3>
        </div>
      </div>
      <div class="row">
        <div class="col col--1">
          <span>Heading 4</span>
        </div>  
        <div class="col">
          <h4 class="no-margins">Heading Four Valera Round</h4>
        </div>
      </div>      
      <br/>
      <h2>Input Fields</h2>
      <br/>
      <InputField type="text" name="name" placeholder="What is your name?" label="Name" icon="user"/>
      <br/>
      <br/>
      <InputField type="text" name="username" placeholder="Username" label="Username" icon="user" hasError value="Peepeepoopoo69"/>
      <br/>
      <br/>
      <InputField type="password" name="password" placeholder="Password" label="Password" icon="key" hasSuccess/>
      <br/>
      <br/>
      <InputField type="password" name="password" placeholder="Password" label="Password" icon="key" disabled/>
      <h2>Buttons</h2>
      <br/>  
      <h4>Solid</h4>
      <div class="row">
        <Button type="button" label="Normie" :icon="['fas', 'meh']" />
        <Button type="button" label="Kraite it!" :icon="['fas', 'box-open']" status="primary"/>
        <Button type="button" label="Secondary" :icon="['fas', 'box-open']" status="info"/>
        <Button type="button" label="Oh no! Cancel!" :icon="['fas', 'heart-broken']" status="danger"/>
        <Button type="button" label="Oh no! Cancel!" :icon="['fas', 'heart-broken']" state="disabled"/>
      </div>
      <br/>
      <div class="row">  
        <Button type="button" label="Oh no! Cancel!" :icon="['fas', 'heart-broken']" state="preloading"/>
        <Button type="button" label="Kraite it!" :icon="['fas', 'box-open']" status="primary" state="preloading"/>
      </div>
      <br/>
      <h4>Outline</h4>
      <div class="row">  
        <Button type="button" label="Kraite it!" :icon="['fas', 'box-open']" status="primary" isOutline/>
        <Button type="button" label="Secondary" :icon="['fas', 'box-open']" status="info" isOutline/>
        <Button type="button" label="Oh no! Cancel!" :icon="['fas', 'heart-broken']" status="danger" isOutline/>
        <Button type="button" label="Kraite it!" :icon="['fas', 'box-open']" status="primary" isOutline state="preloading"/>
      </div>
      <br/> 
      <h4>Text</h4> 
      <div class="row">
        <Button type="button" label="Normie" :icon="['fas', 'meh']" isText/>
        <Button type="button" label="Kraite it!" :icon="['fas', 'box-open']" status="primary" isText/>
        <Button type="button" label="Secondary" :icon="['fas', 'box-open']" status="info" isText/>
        <Button type="button" label="Oh no! Cancel!" :icon="['fas', 'heart-broken']" status="danger" isText/>
      </div>
      <br/> 
      <h4>Rounded</h4>
      <div class="row">
        <Button type="button" label="Normie" :icon="['fas', 'meh']" isRounded/>
        <Button type="button" label="Kraite it!" :icon="['fas', 'box-open']" status="primary" isRounded/>
        <Button type="button" label="Secondary" :icon="['fas', 'box-open']" status="info" isRounded/>
        <Button type="button" label="Oh no! Cancel!" :icon="['fas', 'heart-broken']" status="danger" isRounded/>
        <Button type="button" label="Kraite it!" :icon="['fas', 'box-open']" status="primary" isRounded state="preloading"/>
      </div>     
      <br/>
      <h2>Badges</h2>
      <div class="row">
        <Badge tag="span" text="Hello" />
        <Badge tag="a" text="Default" href="#"/>
        <Badge tag="a" text="Primary" href="#" status="primary"/>
        <Badge tag="a" text="Info" href="#" status="info"/>
        <Badge tag="a" text="Danger" href="#" status="danger"/>
        <Badge tag="a" text="Default" href="#" isRounded/>
        <Badge tag="a" text="Primary" href="#" status="primary" isRounded/>
        <Badge tag="a" text="Info" href="#" status="info" isRounded/>
        <Badge tag="a" text="Danger" href="#" status="danger" isRounded/>
      </div>   
    </div>
  </div>
</template>

<script>
import InputField from '../elements/InputField.vue'
import Button from '../elements/Button.vue'
import Badge from '../elements/Badge.vue'

export default {
  name: 'StyleGuide',
  components: {
    InputField,
    Button,
    Badge
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../assets/scss/_variables.scss';

  .container {
    padding: 24px;
  }
  .temp-banner-holder {
    position: relative;
    background: linear-gradient(126deg, rgba(29, 169, 245, 0.1) 0%, rgb(255, 255, 255) 100%);
    margin-top: $header-height-small-screen + $menu-height; 

    .temp-banner-holder__img {
      width: 100%;
      opacity: 0.5;
      display: block;
    }

    .temp-banner-holder__content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .logo {
      height: 80%;
      width: auto;
    }
  }

  .button, .badge {
    margin-right: 15px;
  }

  @media screen and (min-width: $breakpoint-small) {
    .temp-banner-holder {
      margin-top: $header-height + $menu-height; 
    }
  }
</style>

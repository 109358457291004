import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueCarousel from 'vue-carousel';
import VueMq from 'vue-mq';

import { library } from '@fortawesome/fontawesome-svg-core'
import  { fas }  from '@fortawesome/free-solid-svg-icons' 
import  { fab }  from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import "./assets/scss/kraite.scss";
import router from "./router";

Vue.use(VueRouter)
Vue.use(VueCarousel)
Vue.use(VueMq, {
  breakpoints: { 
    sm: 720,
    md: 1200,
    lg: Infinity,
  },
  defaultBreakpoint: 'sm' 
})

library.add(fas, fab)
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

<template>
  <component :is="tag" class="badge" :class="classes">
    {{ text }}
  </component>
</template>
<script>
export default {
  name: 'Badge',
  props: {
    tag: { type: String, default: "span" },
    text: { type: String, default: "Label" },
    isRounded: { type: Boolean, default: false },
    status: { type: String, default: "normal"},
    link: { type: String, default: "#" },
  },
  computed: {
    classes() {
      let buttonClasses = [
        {'badge--is-rounded': this.isRounded}, 
        {'badge--is-link': this.tag == 'a'},
        { [`badge--${this.status}`]: this.status },
      ];
      return buttonClasses;
    }
  }
};
</script>
<style lang="scss" scoped>
  @import '../assets/scss/_variables.scss';
  @import '../assets/scss/mixins/_font-size.scss';
  @import '../assets/scss/mixins/_badge-status.scss';

  .badge {
    @include size(badge);
    display:flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    padding: 3px 10px;
    border-radius: 3px;
    font-weight: bold;
  }

  .badge--normal {
    @include badge-status($defaultButtonColor, $defaultButtonHoverColor);
  }
  .badge--primary {
    @include badge-status($primaryButtonColor, $primaryButtonHover);
  }

  .badge--info {
    @include badge-status($infoButtonColor, $infoButtonHover);
  }

  .badge--danger {
    @include badge-status($dangerButtonColor, $dangerButtonHover);
  }
</style>
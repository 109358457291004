<template>
  <label class="input-field">
    <input :type="type" 
    :name="name" 
    :placeholder="placeholder" 
    :value="value" 
    :disabled="disabled"
    @input="updateValue" 
    @focus="$emit('focus', $event)" 
    @blur="$emit('blur', $event)"
    class="input-field__input"
    :class="classes"
    >
    <span v-if="label" class="input-field__label">{{ label }}</span>
    <font-awesome-icon  v-if="icon" :icon="icon" class="input-field__icon"/>
  </label>
</template>

<script>
  export default {
    name: 'InputField',
    props: {
      type: { type: String, default: 'text' },
      name: { type: String, require: true },
      label: {type: String},
      placeholder: {type: String},
      icon: { type: String },
      disabled: { type: Boolean },
      hasError: { type: Boolean, default: false },
      hasSuccess: { type: Boolean, default: false },
      value: {},
    },
    computed: {
      classes() {
        let inputClasses = [
          {'input-field__input--has-icon': this.icon}, 
          {'input-field__input--has-error': this.hasError}, 
          {'input-field__input--has-success': this.hasSuccess}
        ];
        return inputClasses;
      }
    },
    methods: {
      updateValue (e) {
        this.$emit('input', e.target.value)
        this.$emit('change', e.target.value)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '../assets/scss/_variables.scss';

  .input-field {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    position: relative;
  }

  .input-field__label {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    transform: translateY(-20px) translateX(-5px) scale(0.75);
  }
  .input-field__input {
    appearance: none;
    box-shadow: none;
    height: 40px;
    border-radius: 3px;
    border: 1px solid rgba($inputDefaultGray, 0.2);
    padding: 0 10px;
    width: 100%;
    font-size: 14px;
    transition: all 0.2s;
    color: $inputDefaultGray;
  }

  .input-field__input::-webkit-input-placeholder { /* Edge */
    color: rgba($inputDefaultGray, 0.5);
  }

  .input-field__input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba($inputDefaultGray, 0.5);
  }

  .input-field__input::placeholder {
    color: rgba($inputDefaultGray, 0.5);
  } 

  .input-field__input--has-icon {
    padding: 0 10px 0 35px;
  }

  .input-field__icon {
    position: absolute;
    left: 10px;
  }

  .input-field__input:focus {
    outline: none;
    box-shadow: 0px 0px 1px 2px rgba($inputFocusedBlue, 0.8);
    background-color: #fff;
  }

  .input-field__input:focus + .input-field__label {
    color: $inputFocusedBlue;
  }

  .input-field__input:focus ~ .input-field__icon {
    color: $inputFocusedBlue;
  }

  .input-field__input--has-error {
    border: 1px solid $inputHasErrorRed;
    background-color: rgba($inputHasErrorRed, 0.1);
  }

  .input-field__input--has-error:focus {
    box-shadow: 0px 0px 1px 2px rgba($inputHasErrorRed, 0.8);
  }

  .input-field__input--has-error + .input-field__label,
  .input-field__input--has-error ~ .input-field__icon,
  .input-field__input--has-error:focus + .input-field__label,
  .input-field__input--has-error:focus ~ .input-field__icon {
    color: $inputHasErrorRed;
  }

  .input-field__input--has-success {
    border: 1px solid $inputHasSuccessGreen;
    background-color: rgba($inputHasSuccessGreen, 0.1);
  }

  .input-field__input--has-success:focus {
    box-shadow: 0px 0px 1px 2px rgba($inputHasSuccessGreen, 0.8);
  }

  .input-field__input--has-success + .input-field__label,
  .input-field__input--has-success ~ .input-field__icon,
  .input-field__input--has-success:focus + .input-field__label,
  .input-field__input--has-success:focus ~ .input-field__icon {
    color: $inputHasSuccessGreen;
  }

  .input-field__input[disabled] {
    border: 1px solid rgba($inputDefaultGray, 0.2);
    background-color: rgba($inputDefaultGray, 0.08);
  }

  .input-field__input[disabled] + .input-field__label,
  .input-field__input[disabled] ~ .input-field__icon,
  .input-field__input[disabled]:focus + .input-field__label,
  .input-field__input[disabled]:focus ~ .input-field__icon {
    color: rgba( $inputDefaultGray, 0.5);
  }

</style>

<template>
  <div>
    <StyleGuide/>
  </div>  
</template>
<script>
import StyleGuide from '../components/StyleGuide.vue'

export default {
  name: 'style-guide',
  components: {
    StyleGuide,
  }
}
</script>

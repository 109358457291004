<template>
  <component :is="tag" class="avatar" :class="classes">
    <img :src="image" v-if="image" />
    <span v-if="!image">{{ firstLetter }}</span>
  </component>
</template>
<script>
export default {
  name: 'Avatar',
  props: {
    tag: { type: String, default: "div" },
    username: { type: String, default: "name" },
    size: { type: String, default: "medium"},
    image: { type: String, default: ""}
  },
  computed: {
    classes() {
      let avatarClasses = [
        { [`avatar--is-${this.size}`]: this.size },
        {'button--has-image': this.image}, 
      ];
      return avatarClasses;
    },
    firstLetter() {
      return this.username.charAt(0);
    }
  }
};
</script>
<style lang="scss" scoped>
  @import '../assets/scss/_variables.scss';

  .avatar {
    display:flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 60px;
    border: 1px solid $infoButtonColor;
    background: $infoButtonColor;
    position: relative;
    text-transform: capitalize;
    color: #fff;
    font-weight: bold;

    &.button--has-image {
      background: $grayBackground;
      border: 1px solid $grayBackground;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      border-radius: 60px;
    }
  }
</style>
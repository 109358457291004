<template>
  <button
    :type="type"
    :disabled="state === 'disabled'"
    class="button"
    :class="classes"
    @click="handleClick"
  >
    <span v-if="label" class="button__label">{{ label }}</span>
    <font-awesome-icon  v-if="icon" :icon="icon" class="button__icon"/>
    <div class="button__preloader"></div>
  </button> 
</template>
<script>
  export default {
    name: 'Button',
    props: {
      type: { type: String, default: 'button' },
      label: {type: String},
      status: {type: String, default: 'normal'},
      icon: { type: Array },
      isIcon: { type: Boolean },
      isOutline: { type: Boolean },
      isText: { type: Boolean },
      isSearch: { type: Boolean },
      isRounded: { type: Boolean },
      size: { type: String, default: 'normal'},
      state: { type: String, default: 'normal'},
    },
    computed: {
      classes() {
        let buttonClasses = [
          {'button--has-icon': this.icon}, 
          {'button--is-icon': this.isIcon},
          {'button--is-outline': this.isOutline},
          {'button--is-text': this.isText},
          {'button--is-search': this.isSearch},
          {'button--is-rounded': this.isRounded}, 
          { [`button--${this.status}`]: this.status },
          {'button--is-preloading': this.state === 'preloading'}, 
        ];
        return buttonClasses;
      }
    },
    methods: {
      handleClick(e) {
        this.$emit("click", e);
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import '../assets/scss/_variables.scss';
  @import '../assets/scss/mixins/_button-status.scss';

  .button {
    display:flex;
    justify-content: center;
    align-items: center;
    min-width: 80px;
    width: auto;
    height: 40px;
    padding: 0 32px;
    border-radius: 60px;
    box-shadow: 0 4px 6px rgba($defaultGray,.11), 0 1px 3px rgba($defaultGray,.08);
    border: 1px solid $defaultButtonColor;
    position: relative;
  }

  .button:hover {
    box-shadow: 0 4px 6px rgba($defaultGray,.25), 0 1px 3px rgba($defaultGray,.08);
    cursor: pointer;
  }

  .button:focus {
    outline: none;
    box-shadow: 0px 0px 1px 2px rgba($inputFocusedBlue, 0.8) !important;
  }

  .button[disabled],.button--is-preloading {
    cursor: default;
    box-shadow: none !important;
    opacity: 0.5;

    &:hover {
      box-shadow: none !important;  
    }
  }

  .button__preloader {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 13px;
    height: 13px;

    &:before {
      content: '';
      position: relative;
      display: block;
      width: 300%;
      height: 300%;
      box-sizing: border-box;
      margin-left: -100%;
      margin-top: -100%;
      border-radius: 45px;
      background-color: $defaultGray;
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }
    
    &:after {
      content: '';
      position: absolute;
      left: 0; 
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: $defaultGray;
      border-radius: 15px;
      box-shadow: 0 0 8px rgba($defaultGray,.3);
      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
    }
  }

  .button--size-normal {
    min-width: 100px;
  }

  .button__icon {
    margin-left: 10px;
    font-size: 16px;
  }

  .button__label {
    font-family: 'Varela Round', sans-serif;
    font-size: 14px;
    font-weight: bold;
  }

  .button--is-preloading {
    .button__label, .button__icon {
      visibility: hidden;
    }

    .button__preloader {
      display: block;
    }
  }

  .button--normal {
    @include button-status($defaultButtonColor, $defaultButtonHoverColor);
  }

  .button--primary {
    @include button-status($primaryButtonColor, $primaryButtonHover);
  }

  .button--info {
    @include button-status($infoButtonColor, $infoButtonHover);
  }

  .button--danger {
    @include button-status($dangerButtonColor, $dangerButtonHover);
  }

  .button--facebook {
    @include button-status($facebookButtonColor, $facebookButtonHover);
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(.33);
    }
    80%, 100% {
      opacity: 0;
    }
  }

  @keyframes pulse-dot {
    0% {
      transform: scale(.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(.8);
    }
  }
</style>
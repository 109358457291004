<template>
  <div class=" col col--5 register-link text-center justified-center aligned-center no-margins">
    <h2>Hello there!</h2>
    <p class="medium-text">
      Sell, Trade, or Donate with kraite. <br/>
      If you are new to krate,
    </p>
    <router-link :to="{name: 'register'}" tag="span">
      <Button type="button" label="Create an Account" isOutline/>
    </router-link>  
  </div>  
</template>
<script>
  import Button from '../elements/Button.vue'
  export default {
    name: 'registerLink',
    components: {
      Button,
    },
    computed: {
      currentRouteName() {
        return this.$route.name;
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import '../assets/scss/_variables.scss';

  .register-link {
    background-color: $mainGreen;
    background-image: linear-gradient(0deg, rgba(#028FD6, 0.90) 0%, rgba($mainGreen, 0.70) 100%), url('../assets/images/repeat-bg.png');
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    background-size: content;

    h2, p {
      color: #fff;
    }
  }

  @media (max-width: $breakpoint-small) {
    .register-link {
      display: none;
    }
  }
</style>
<template>
  <div class="search">
    <InputFeild type="search" name="search" placeholder="What are you looking for?" class="search__input"/>
    <Button type="button" :icon="['fa', 'search']" class="search__button" isSearch/>
  </div>  
</template>
<script>
import InputFeild from '../elements/InputField.vue'
import Button from '../elements/Button.vue'
export default {
  name: 'Search',
  components: {
    InputFeild,
    Button
  }
} 
</script>
<style lang="scss" scoped>
  .search {
    position: relative;
  }

  .search__button {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>
<template>
  <div>
    <div class="featured-categories">
      <h3 class="text-center">Featured Categories</h3>
      <div class="container">
        <div class="row">
          <div class="col">
            <img alt="kraite featured one" src="../assets/images/featured1b.png" class="carousel__item-img">
            <h5 class="text-center">Donations</h5>
          </div>
          <div class="col">
            <img alt="kraite featured two" src="../assets/images/featured2b.png" class="carousel__item-img">
            <h5 class="text-center">Fashion</h5>
          </div>
          <div class="col">
            <img alt="kraite featured three" src="../assets/images/featured3.png" class="carousel__item-img">
            <h5 class="text-center">Electronics &amp; Gadgets</h5>
          </div>
          <div class="col">
            <img alt="kraite featured four" src="../assets/images/featured4.png" class="carousel__item-img">
            <h5 class="text-center">Home &amp; Appliances</h5>
          </div>
        </div>
        <router-link :to="{name: 'category'}" tag="span">
          <Button type="button" label="View All Categories" status="primary" isOutline class="featured-categories__button"/>
        </router-link>  
      </div>
    </div>
    <div class="recent-items">
      <h3 class="text-center">Recently Posted Items</h3>
      <div class="container">
        <div class="row">
          <div class="col col--3 list-item">
            <div class="img-holder">
              <img alt="kraite featured one" src="../assets/data/product1.jpg" class="carousel__item-img">
            </div>  
            <h5>Minecraft Creeper Snapback</h5>
            <p>￥ 7,094</p>
          </div>
          <div class="col col--3 list-item">
            <div class="img-holder">
              <img alt="kraite featured two" src="../assets/data/product2.jpg" class="carousel__item-img">
            </div>  
            <h5>Fjalraven Kanken Art Blue Fable</h5>
            <p>￥ 12,000</p>
          </div>
          <div class="col col--3 list-item">
            <div class="img-holder">
              <img alt="kraite featured three" src="../assets/data/product3.jpg" class="carousel__item-img">
            </div>  
            <h5>Nya Q&Q Frost</h5>
            <p>￥ 7,999</p>
          </div>
          <div class="col col--3 list-item">
            <div class="img-holder">
              <img alt="kraite featured four" src="../assets/data/product4.jpg" class="carousel__item-img">
            </div>  
            <h5>New Balance CM997H</h5>
            <p>￥ 10,080</p>
          </div>
          <div class="col col--3 list-item">
            <div class="img-holder">
              <img alt="kraite featured one" src="../assets/data/product5.jpg" class="carousel__item-img">
            </div>  
            <h5>Hydro Flask Pink</h5>
            <p>￥ 4,094</p>
          </div>
          <div class="col col--3 list-item">
            <div class="img-holder">
              <img alt="kraite featured two" src="../assets/data/product6.jpg" class="carousel__item-img">
            </div>  
            <h5>Palladium Sneaker</h5>
            <p>￥ 12,000</p>
          </div>
          <div class="col col--3 list-item">
            <div class="img-holder">
              <img alt="kraite featured three" src="../assets/data/product7.jpg" class="carousel__item-img">
            </div>  
            <h5>Lego Minecraft</h5>
            <p>￥ 7,999</p>
          </div>
          <div class="col col--3 list-item">
            <div class="img-holder">
              <img alt="kraite featured four" src="../assets/data/product8.jpg" class="carousel__item-img">
            </div>  
            <h5>Nintendo Switch</h5>
            <p>￥ 32,080</p>
          </div>
        </div>
        <br/>
        <router-link :to="{name: 'category'}" tag="span">
          <Button type="button" label="View More" status="primary" isOutline class="featured-categories__button"/>
        </router-link>  
      </div>
    </div>    
  </div>
</template>
<script>
  import Button from '../elements/Button.vue'

  export default {
    name: 'home',
    components: {
      Button
    }
  }
</script>
<style lang="scss" scoped>
  @import '../assets/scss/_variables.scss';

  .featured-categories {
    overflow: auto;
    padding-bottom: 30px;

    img {
      max-width: 270px;
      margin: 0 auto;
    }
  }

  .featured-categories__button {
    margin: 0 auto;
  }

  .recent-items {
    overflow: auto;
    padding-bottom: 30px;
    background: $grayBackground;

    .row{
      flex-wrap: wrap;
    }

    .row .col{
      flex-grow: 0;
      flex-basis: 1;
    }
  }

  .img-holder {
    position: relative;
    max-height: 250px;
    min-height: 250px;
    background: $grayBackground;
    border: solid 1px $grayBackground;
    overflow: hidden;
    display: flex;
    align-items: center;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }
  
  .list-item {
    width: calc(100% - 20px);
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    border: solid 1px rgba(25,26,32,.06); 

    &:hover {
      box-shadow: 0 3px 9px rgba(25,26,32,.06);
    }

    h5 {
      margin: 10px 0;
    }

    p {
      color: $mainGreen;
      font-weight: bold;
      margin: 10px 0;
    }
  }

</style>
import Vue from "vue";
import Router from "vue-router";
import MainHeader from "./components/MainHeader";
import MainFooter from "./components/MainFooter";
import Hero from "./components/Hero";
import Home from "./views/Home.vue";
import Category from "./views/Category.vue";
import StyleGuide from "./views/StyleGuide.vue";
import LoginRegister from "./views/LoginRegister.vue";

Vue.use(Router);

export default new Router({
  linkExactActiveClass: "active",
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "home",
      meta: {key: 0 }, 
      components: {
        header: MainHeader,
        hero: Hero,
        default: Home,
        footer: MainFooter
      }
    },
    {
      path: "/",
      name: "category",
      meta: {key: 0 }, 
      components: {
        header: MainHeader,
        default: Category,
        footer: MainFooter
      }
    },
    {
      path: "/style-guide",
      name: "style-guide",
      meta: {key: 1 }, 
      components: {
        header: MainHeader,
        default: StyleGuide,
        footer: MainFooter
      }
    },
    {
      path: "/login",
      name: "login",
      meta: {key: 2 }, 
      components: {
        default: LoginRegister,
      }
    },
    {
      path: "/register",
      name: "register",
      meta: {key: 3 }, 
      components: {
        default: LoginRegister,
      }
    },
  ]
});

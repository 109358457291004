<template>
  <div class="main-header">
    <div class="main-header-content">
      <div class="main-header__container">
        <router-link :to="{name: 'home'}" tag="span">
          <img alt="kraite logo" src="../assets/images/kraite-header-logo.png" class="main-header__logo">
        </router-link>  
        <Search />
        <div class="main-header__buttons">
          <Avatar :username="'Michael'" :image="'/media/avatar.png'"/>
          <router-link :to="{name: 'register'}" tag="span">
            <Button type="button" label="Register" class="main-header__register-button"/>
          </router-link>  
          <router-link :to="{name: 'login'}" tag="span">
            <Button type="button" label="Login" status="primary" class="main-header__login-button"/>
          </router-link>
        </div>    
        
      </div>  
    </div>
    <div class="menu-holder">
      <Menu></Menu> 
    </div>
  </div>    
</template>

<script>
import Button from '../elements/Button.vue'
import Avatar from '../elements/Avatar.vue'
import Menu from '../components/Menu.vue'
import Search from '../components/Search.vue'

export default {
  name: 'MainHeader',
  components: {
    Avatar,
    Button,
    Menu,
    Search
  }
}
</script>

<style scoped lang="scss">
  @import '../assets/scss/_variables.scss';
  $header-height: 65px;
  $max-container-width: 1280px;

  .main-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: #fff;
  }
  
  .main-header-content {
    //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    //box-shadow: 0 3px 9px rgba(25,26,32,.06);
    //border-bottom: 1px solid rgb(231, 235, 234);
    display: flex;
    height: $header-height-small-screen;
    align-items: flex-start;
    justify-content: center;
    z-index: 15;
  }
  .main-header__container {
    max-width: $max-container-width;
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;

    .search {
      width: calc(100% - 20px);
      margin: 0 10px;
    }
  }

  .main-header__buttons {
    position: absolute;
    top: 12px;
    right: 10px;

    .button {
      margin-left: 15px;
    }
  }

  .main-header__register-button {
    display: none;
  }

  .main-header__logo {
    max-width: 135px;
    padding: 10px 15px;

    &:hover {
      cursor: pointer;
    }
  }

  .menu-holder {
    width: 100%;
    background: #fff;
    height: $menu-height;
    //box-shadow: 0 3px 9px rgba(25,26,32,.06);
    border-bottom: 1px solid rgb(231, 235, 234);
  }

  .main-header__buttons {
    display: flex;
  }

  @media screen and (min-width: $breakpoint-small) {
    .main-header__container {
      flex-direction:row;
      align-items: center;
      justify-content: center;
      padding: 0 20px;

      .search {
        width: auto;
        flex-grow: 1;
      }
    }

    .main-header__register-button {
      display: block;
    }

    .main-header-content {
      height: $header-height;
    }

    .main-header__buttons {
      position: relative;
      top: 0;
      right: 0;
    }
  }
</style>

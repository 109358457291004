
<template>
  <div>
    <h1>Footer</h1>
    <router-link :to="{name: 'style-guide'}" tag="span">
      <Button type="button" label="Style Guide" status="normal" isText />
    </router-link>
  </div>
</template>

<script>
import Button from '../elements/Button.vue'

export default {
  name: 'Footer',
  components: {
    Button
  }
}
</script>
<template>
  <div class="col col--7 register-form">
    <router-link :to="{name: 'home'}" tag="span">
      <img alt="kraite logo" src="../assets/images/kraite-header-logo.png" class="register-form__logo">
    </router-link>  
    <br/>
    <div class="text-center">
      <h2>Register to kraite</h2>
      <div class="register-form__social-login">
        <p class="medium-text">
          Register with your social media account
        </p> 
        <div class="register-form__social-login-buttons">
          <Button type="button" :icon="['fab', 'facebook-f']" label="Sign in with Facebook" status="facebook" isRounded/>
          <Button type="button" :icon="['fab', 'google']" label="Sign in with Google"  status="danger" isRounded/>
        </div>         
      </div>
      <div class="register-form__form">
        <br/>
        <p class="medium-text">
          Or register with your email
        </p> 
        <InputField type="email" name="email" placeholder="Email" label="Username" icon="envelope"/>
        <InputField type="text" name="username" placeholder="Username" label="Username" icon="user"/>
        <InputField type="password" name="password" placeholder="Password" label="Password" icon="key"/>
        <InputField type="password" name="verify-password" placeholder="Verify Password" label="Verify Password" icon="key"/>
        <Button type="button" label="Submit" status="primary"/>
        <br/>
      </div>
      <div class="register-form__register">
        <p class="medium-text">
          Already a member?
        </p> 
        <router-link :to="{name: 'login'}" tag="span">
          <Button type="button" label="Sign In" status="primary" isOutline/>
        </router-link>  
      </div>  
    </div>  
  </div>  
</template>
<script>
  import Button from '../elements/Button.vue'
  import InputField from '../elements/InputField.vue'
  export default {
    name: 'registerForm',
    components: {
      Button,
      InputField
    }
  }
</script>
<style lang="scss" scoped>
  @import '../assets/scss/_variables.scss';

  .register-form {
    height: 100%;
  }

  .register-form__logo {
    max-width: 100px;

    &:hover {
      cursor: pointer;
    }
  }

  .register-form__social-login {
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;

    .button { 
      margin: 15px;
    }
  }

  .register-form__social-login-buttons {
    display: flex;
    justify-content: center;
  }

  .register-form__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;

    p {
      margin: 10px 20px 20px;
    }

    .input-field {
      margin: 20px 0px 20px;
    }

    .button {
      width: 200px;
      align-self: center;
    }
  }

  .register-form__register {
    display: none;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;

    .button {
      width: 200px;
      margin: 10px auto 0;
    }
  }

  @media (max-width: $breakpoint-small) {
    .register-form__form {
      margin-bottom: 20px;
      border-bottom: 1px solid #ddd;
    }

    .register-form__register {
      display: flex;
    }
  }
</style>
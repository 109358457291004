<template>
  <div class="hero">
    <carousel class="carousel container" :perPage="1" :autoplay="true" :loop="true" :autoplayTimeout="10000">
      <slide class="carousel__item">
        <div class="row">
          <div class="col col--6 carousel__item-content-holder">  
            <h1 class="carousel__item-header">Donate. Reach out to those in need.</h1>
            <p class="carousel__item-p"> With kraite, you have an option to donate instead of the usual selling.
            Try it for free and reach out to those who really need it.</p>
            <router-link :to="{name: 'login'}" tag="span">
              <Button type="button" label="Post an Item" status="primary"/>
            </router-link>  
          </div>
          <div class="col col--6 carousel__item-img-holder">
            <img alt="kraite banner donate" src="../assets/images/banner1.png" class="carousel__item-img">
          </div>
        </div>
      </slide>   
      <slide class="carousel__item">
        <div class="row">
          <div class="col col--6 carousel__item-content-holder">  
            <h1 class="carousel__item-header">Trade. Meet people with the same hobbies.</h1>
            <p class="carousel__item-p">If you are a hobbyist or collector, kraite lets you trade with people of the same interests. 
            Try it for free and start trading.</p>
            <router-link :to="{name: 'login'}" tag="span">
              <Button type="button" label="Post an Item" status="primary"/>
            </router-link>  
          </div>
          <div class="col col--6 carousel__item-img-holder">
            <img alt="kraite banner trade" src="../assets/images/banner2.png" class="carousel__item-img">
          </div>
        </div>
      </slide>
      <slide class="carousel__item">
        <div class="row">
          <div class="col col--6 carousel__item-content-holder">  
            <h1 class="carousel__item-header">Sell. Make your customers happy.</h1>
            <p class="carousel__item-p">If you are selling goods or just need to free up your closet space, kraite helps you sell your items conveniently and fast.</p>
            <router-link :to="{name: 'login'}" tag="span">
              <Button type="button" label="Post an Item" status="primary"/>
            </router-link>  
          </div>
          <div class="col col--6 carousel__item-img-holder">
            <img alt="kraite banner sell" src="../assets/images/banner3.png" class="carousel__item-img">
          </div>
        </div>
      </slide>      
    </carousel>
  </div>
</template>
<script>
  import { Carousel, Slide } from 'vue-carousel';
  import Button from '../elements/Button.vue'

  export default {
    name: 'hero',
    components: {
      Carousel,
      Slide,
      Button
    }
  }
</script>
<style lang="scss" scoped>
  @import '../assets/scss/_variables.scss';

  .hero{
    position: relative;
    width: 100%;
    overflow-x: hidden;
    margin-top: $header-height-small-screen + $menu-height; 
    padding-bottom: 20px;
    background: $grayBackground;
  }

  .carousel {
    .col {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: flex-start;
      align-items: center;

      button {
        margin: 10px 0 20px;
        text-align: center;
      }
    }
  }

  .carousel__item-img {
    width: 80%;
  }

  .carousel__item-header {
    margin: 10px 0 0;
    position: relative;
    z-index: 15;
    text-align: center;
    width: 94%
  }

  .carousel__item-p {
    line-height: 2;
    text-align: justify;
    text-justify: newspaper;
    margin-left: 0;
    text-align: center;
    width: 94%
  }

  .carousel__item-content-holder {
    z-index: 10;
  }

  .carousel__item-img {
    margin-top: -50px;
  }

  @media screen and (min-width: $breakpoint-small) {
    .hero{
      margin-top: $header-height + $menu-height; 
    }

    .carousel {
      .col {
        align-items:flex-start;
        justify-content: center;

        button {
          margin: 0 0 0 80px;
        }
      }
    }

    .carousel__item-header {
      text-align: left;
      margin: 30px 0 0 80px;
      width: 100%;
      max-width: 500px;
      text-align: left;
    }

    .carousel__item-p {
      text-align: left;
      margin-left: 80px;
      max-width: 400px;
    }

    .carousel__item-img {
      width: 100%;
      margin-top: 0;
    }
  }

</style>
<template>
  <div id="app">
    <router-view name="header"></router-view>
    <router-view name="hero" v-if="isHome"></router-view>
    <div class="main-content row" :class="{'container': hasColumn === true}">
      <div class="col col--2 menu-holder" v-if="hasColumn === true">
        
      </div>
      <div class="col main-view">  
        <transition name="fade" mode="out-in" appear>
          <router-view :key="key"/>
        </transition>
      </div>    
    </div>
    <router-view name="footer"></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  computed: {
    key() {
      return this.$route.meta.key !== undefined 
      ? this.$route.meta.key 
      : this.$route
    },
    hasColumn() {
      let name = this.$route.name
      return name !== 'login' && 
      name !== 'register' &&
      name !== 'style-guide' &&
      name !== 'home'
    },
    isHome() {
      let name = this.$route.name
      return name === 'home' 
    }
  }
}
</script>

<style scoped lang="scss">
  @import '/assets/scss/_variables.scss';

  .main-content {
    position: relative; 
    z-index: 2;

    .main-view, .menu-holder {
      margin: 0px;
      padding: 0px;
    }

    &.container {
      margin-top: $header-height;

      .main-view{
        border-radius: 5px;
      }

      .menu-holder {
        // border: 1px solid rgba($inputDefaultGray, 0.2);
        box-shadow: 1px 11px 10px 10px rgba(0,0,0,0.02);
        border-radius: 10px;
        min-width: 280px;
        width: 280px;
        background: #fff;
        margin: 10px;
        padding: 10px;
      }
    }
  }

  // .banner-cover {
  //   position: absolute;
  //   z-index: 1;
  //   background-color: rgb(231, 235, 234);
  //   background-image: url('assets/images/repeat-bg.png');
  //   // background-color: $mainGreen;
  //   // background-image: linear-gradient(0deg, rgba(#028FD6, 0.90) 0%, rgba($mainGreen, 0.70) 100%), url('assets/images/repeat-bg.png');
  //   height: 42vh;
  //   width: 100%;
  // } 

  .fade-enter-active, .fade-leave-active {
    transition: opacity 100ms ease-in;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
